import {
  differenceInDays,
  format,
  formatDistanceToNow,
  isValid,
  parse,
} from "@goodcollect/shared/dates";
import { fr } from "date-fns/locale";
// const SATURDAY = 6;
// const SUNDAY = 0;
type DateFormat =
  | "dd/MM/yyyy à HH:mm:ss"
  | "dd/MM/yyyy"
  | "dd MMMM yyyy"
  | "MM/yyyy"
  | "dd/MM"
  | "dd MMMM yyyy à HH:mm:ss"
  | "EEEE dd MMMM yyyy"
  | "EEEE dd MMMM yyyy à HH:mm:ss";
export const formatDate = ({
  date,
  dateFmt = "dd/MM/yyyy",
}: {
  date: string | Date;
  dateFmt?: DateFormat;
}) => {
  let initialDate = new Date(date);
  if (!isValid(initialDate)) {
    console.log("invalid date", initialDate);
    initialDate = parse(date.toString(), "dd/MM/yyyy", new Date(), {
      locale: fr,
    });
  }

  const formattedDate = format(initialDate, dateFmt, {
    locale: fr,
  });
  return formattedDate;
};
/**
 *  Helper function to add business days
 * */
export const addBusinessDays = ({
  date,
  daysToAdd,
}: {
  date: Date;
  daysToAdd: number;
}) => {
  let count = 0;
  // add a vriable for not change the function's params and dodge some undesirable effects
  let newDate = new Date(date);
  while (count < daysToAdd) {
    newDate = new Date(date.setDate(date.getDate() + 1));
    // If the new date is a Saturday or Sunday, continue to the next day
    if (newDate.getDay() !== 0 && newDate.getDay() !== 6) {
      count++;
    }
  }
  return newDate;
};
export const formatDateFromNow = ({
  date,
  maxDays = 2,
  dateFmt = "dd/MM/yyyy",
}: {
  date: string | Date;
  maxDays?: number;
  dateFmt?: DateFormat;
}) => {
  // check if there is 7days between now and then
  const initialDate = new Date(date);
  const now = new Date();
  const diff = Math.abs(now.getTime() - initialDate.getTime());
  const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
  if (diffDays > maxDays) {
    return formatDate({ date, dateFmt });
  }

  return formatDistanceToNow(new Date(date), {
    locale: fr,
    addSuffix: true,
  });
};

export const parseStringToDate = (date: string): Date => {
  const parsedDate = parse(date, "dd/MM/yyyy", new Date(), {
    locale: fr,
  });

  return new Date(parsedDate);
};

// const isDateOnWeekend = (date: Date) => {
// 	return date.getDay() === SUNDAY || date.getDay() === SATURDAY;
// };

export const getDateDifferenceInDays = (date1: Date, date2: Date): number => {
  // Add One day to include current day.
  const dateDifferenceInDays = differenceInDays(date1, date2) + 1;
  return dateDifferenceInDays;
};
